import { forwardRef, HTMLAttributes, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'

export const Div100vh = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ style, ...other }, ref) => {
	const [height, setHeight] = useState(window.innerHeight)

	useEffect(() => {
		const updateVh = () => {
			setHeight(window.innerHeight)
		}

		window.addEventListener('resize', updateVh)

		return () => window.removeEventListener('resize', updateVh)
	}, [])

	const styleWithRealHeight = {
		...style,
		height: height ? `${height}px` : '100vh',
	}

	return <div ref={ref} style={styleWithRealHeight} {...other} />
})

Div100vh.displayName = 'Div100vh'

export const Paper100Div = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ style, ...other }, ref) => {
	const theme = useTheme()
	return <Div100vh ref={ref} {...other} style={{ ...style, background: theme?.palette.background.paper ?? '#fff' }} />
})

Paper100Div.displayName = 'ThemedDiv'
