import React from 'react'

interface DrawerBodyProps {
	className?: string
	children: React.ReactNode
}

export function MobileDrawerBody(props: DrawerBodyProps) {
	return <section className={props.className}>{props.children}</section>
}
