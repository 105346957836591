import React from 'react'
import { Grid } from '@mui/material'

interface ThreeColumnGridProps {
	headerLocation?: 'left' | 'center' | 'right'
	containerClasses?: string
	children: React.ReactNode[]
	onClick?: () => void
}

export function ThreeColumnGrid(props: ThreeColumnGridProps) {
	const [leftContainer, centerContainer, rightContainer] = props.children

	return (
		<Grid
			container
			className={props.containerClasses}
			onClick={props.onClick}
			style={{ cursor: props.onClick ? 'pointer' : '' }}
		>
			<Grid item xs={2}>
				{leftContainer}
			</Grid>
			<Grid item xs={8}>
				{centerContainer}
			</Grid>
			<Grid item xs={2}>
				{rightContainer}
			</Grid>
		</Grid>
	)
}
