import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { IconButton, Typography } from '@mui/material'
import { StickyHeader } from '../Layout/StickyHeader'
import { ThreeColumnGrid } from '../Layout/ThreeColumnGrid'

interface IDrawerHeaderProps {
	onClose?: () => void
	textAlign?: 'left' | 'center' | 'right'
	children: string | React.ReactNode
	underline?: string | React.ReactNode
}

export function MobileDrawerHeader(props: IDrawerHeaderProps) {
	return (
		<StickyHeader>
			<ThreeColumnGrid>
				<>
					{props.onClose && (
						<IconButton onClick={props.onClose}>
							<ArrowBackIosIcon />
						</IconButton>
					)}
				</>
				<>
					<Typography variant="h3" align={props.textAlign ?? 'center'} style={{ width: '100%' }}>
						{props.children}
					</Typography>
				</>
			</ThreeColumnGrid>
			{props.underline && <div>{props.underline}</div>}
		</StickyHeader>
	)
}
