import React from 'react'

export function useCameraHook(): [React.Ref<any>, () => string] {
	const webcamRef = React.useRef(null)

	const capture = React.useCallback(() => {
		if (!webcamRef?.current) return

		// @ts-ignore
		const imageSrc = webcamRef.current.getScreenshot()

		return imageSrc
	}, [webcamRef])

	return [webcamRef, capture]
}
