import { FormControl, InputLabel, TextareaAutosize } from '@mui/material'

interface FormikTextAreaProps {
	name: string
	label: string
	placeholder?: string
}

interface PlainTextAreaProps extends Omit<FormikTextAreaProps, 'name'> {
	value?: string
	// eslint-disable-next-line no-unused-vars
	onUpdate: (value: string) => void
	fullWidth?: boolean
}

export function PlainTextArea(props: PlainTextAreaProps) {
	const handleOnChange = (event: any) => {
		const value: string = event.target.value
		props.onUpdate?.call(props.onUpdate, value)
	}

	return (
		<>
			<FormControl fullWidth={props.fullWidth}>
				<InputLabel>{props.label}</InputLabel>
				<TextareaAutosize
					minRows={3}
					style={{ width: '100%', marginTop: '44px' }}
					value={props.value}
					placeholder={props.placeholder}
					onChange={handleOnChange}
				/>
			</FormControl>
		</>
	)
}
