import React from 'react'
import { Paper } from '@mui/material'

interface StickyHeaderProps {
	children: React.ReactNode
	elevation?: number
	className?: string
}

export const StickyHeader = (props: StickyHeaderProps) => {
	const { children, elevation = 2, className = '' } = props

	return (
		<Paper
			className={className}
			elevation={elevation}
			style={{
				position: 'sticky',
				top: 0,
				zIndex: 1,
			}}
		>
			{children}
		</Paper>
	)
}
