import React, { useCallback, useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { Button, Menu, MenuItem } from '@mui/material'
import { useCameraHook } from './useCameraHook'
import { ImageVm } from './camera.types'
import { MobileDrawer, MobileDrawerBody, MobileDrawerFooter, MobileDrawerHeader } from '../MobileDrawer'
import { PlainTextArea } from '../Layout/PlainTextArea'

interface CameraProps {
	fileName: string
	open: boolean
	onClose?: () => void
	// eslint-disable-next-line no-unused-vars
	onSubmit?: (image: ImageVm) => void
}

export function CameraDrawer(props: CameraProps) {
	const { fileName, open, onClose, onSubmit } = props

	const [webcamRef, capture] = useCameraHook()
	const [description, setDescription] = useState<string | undefined>()

	const [deviceId, setDeviceId] = useState<string | undefined>();
	const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

	const handleDevices = useCallback(
		(mediaDevices: MediaDeviceInfo[]) =>
			setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
		[setDevices]
	)

	useEffect(
		() => {
			navigator.mediaDevices.enumerateDevices().then(handleDevices);
		},
		[handleDevices]
	)

	useEffect(() => {
		setDeviceId(devices?.[devices.length - 1]?.deviceId)
	}, [devices])

	const handleCapture = () => {
		const image: ImageVm = {
			fileName: `${fileName}.jpg`,
			description: description,
			data: capture(),
		}

		if (!image.data) 
			return
		if (onSubmit)
			onSubmit(image)
	}

	console.log(deviceId)

	return open && deviceId ? (
		<MobileDrawer open={true} onClose={onClose}>
			<MobileDrawerHeader onClose={onClose}>Take a photo</MobileDrawerHeader>

			<MobileDrawerFooter>
				<div style={{ display: 'flex' }}>
					<Button onClick={handleCapture} endIcon={<AddAPhotoIcon />} fullWidth>
						Create photo
					</Button>

					<BasicMenu
						items={devices}
						selected={deviceId}
						onChanged={x => setDeviceId(x.deviceId)}
					/>
				</div>
			</MobileDrawerFooter>

			<MobileDrawerBody>
				<div> 
					<Webcam
						key={deviceId}
						audio={false}
						ref={webcamRef}
						screenshotFormat="image/jpeg"
						videoConstraints={{ deviceId: deviceId }}
					/>
				</div>

				<PlainTextArea fullWidth label="Photo description" placeholder="..." onUpdate={setDescription} />
			</MobileDrawerBody>
		</MobileDrawer>
	) : null
}

interface MenuProps {
	items: MediaDeviceInfo[]
	selected: string | undefined
	// eslint-disable-next-line no-unused-vars
	onChanged: (item: MediaDeviceInfo) => void
}

function BasicMenu(props: MenuProps) {
	const { items, selected, onChanged } = props

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const handleClick = (event: any) => {
		setAnchorEl(event?.currentTarget ?? null)
	}

	const handleChanged = (item: MediaDeviceInfo) => () => {
		onChanged(item)
		setAnchorEl(null)
	}

	return (
		<>
			<Button variant="outlined" fullWidth={false} style={{ marginLeft: '16px' }} onClick={handleClick}>
				<CameraAltIcon />
			</Button>

			{anchorEl && (
				<Menu anchorEl={anchorEl} open={true} onClose={() => handleClick(null)}>
					{items.map(x => (
						<MenuItem key={x.deviceId} onClick={handleChanged(x)} disabled={x.deviceId === selected}>
							{x.label.replace(/\(.*\)/, '')}
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	)
}
