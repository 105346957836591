import './App.css';
import { CameraDrawer } from './Camera/CameraDrawer';
import CustomWebcam from './Camera/CustomWebcam';

function App() {
  return (
    <div className="App">
      <CameraDrawer fileName={"CameraDrawer.png"} open={true} />
      {/* <CustomWebcam /> */}
    </div>
  );
}

export default App;
