import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, styled } from '@mui/material'
import { Paper100Div } from '../Layout/Div100vh'

interface DrawerProps extends MuiDrawerProps {}

const StyledDrawer = styled(MuiDrawer)({
	width: '100vw',
	height: '100vh',

	'.MuiPaper-root': {
		width: '100vw',
		height: '100vh',
		display: 'flex',
	},
})

export function MobileDrawer(props: DrawerProps) {
	return (
		<StyledDrawer
			{...props}
			PaperProps={{
				style: { width: '100%', display: 'flex' },
				component: Paper100Div,
			}}
		></StyledDrawer>
	)
}
